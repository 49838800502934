var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"status-container"},[_c('div',{staticStyle:{"width":"80px","height":"70px","text-align":"center","float":"left"}},[_vm._t("typeImg"),_c('div',{staticStyle:{"margin-top":"4px","font-size":"14px","color":"rgba(0, 0, 0.85)","font-weight":"500"}},[_vm._v(" "+_vm._s(_vm.getSourceType(_vm.sourceType))+" ")])],2),_c('div',{staticStyle:{"float":"right","margin-left":"10px","height":"70px","line-height":"84px","font-size":"12px","color":"rgba(0, 0, 0, 0.45)"}},[_vm._v(" 总数 ")]),_c('div',{style:({
      float: 'right',
      height: '70px',
      lineHeight: '70px',
      fontSize: '40px',
      color: '#555761',
      fontWeight: 400,
      cursor: 'pointer'
    })},[_c('span',{on:{"click":function($event){return _vm.$emit('changeList', _vm.type)}}},[_vm._v(_vm._s(_vm.countTotal))])]),_c('div',{staticStyle:{"clear":"both"}}),_c('div',{staticStyle:{"display":"flex","flex-wrap":"wrap","margin-top":"16px","margin-left":"-24px","padding":"0 8px"}},_vm._l((_vm.statusCount),function(item){return _c('div',{key:item.name,staticStyle:{"display":"flex","align-items":"center","justify-content":"space-between","width":"50%","padding":"4px 0 0 24px"}},[_c('div',[_c('div',{style:({
            width: '6px',
            height: '6px',
            borderRadius: '50%',
            background: _vm.getStatusColor(item.name),
            display: 'inline-block'
          })}),_c('div',{staticStyle:{"display":"inline-block","color":"rgba(0, 0, 0, 0.65)","font-size":"12px","width":"48px","margin-left":"6px"}},[_vm._v(" "+_vm._s(_vm.$t(`source_status.${item.name}`))+" ")])]),_c('div',{style:({
          fontSize: '16px',
          color: _vm.getStatusColor(item.name),
          cursor: 'pointer'
        }),on:{"click":function($event){return _vm.$emit('filterByStatus', item.name)}}},[_vm._v(" "+_vm._s(item.value)+" ")])])}),0),_c('div',{staticClass:"custom-progress"},_vm._l((_vm.sourceStatuses),function(item){return _c('div',{key:item,ref:`${item}Div`,refInFor:true,staticClass:"progress-bg",style:({ background: _vm.getStatusColor(item) })})}),0),_c('div',{staticStyle:{"font-weight":"400","color":"#2e8df5","margin":"16px 0 8px 4px"}},[_vm._v(" 未恢复告警分布 ")]),_c('a-descriptions',{attrs:{"column":4,"layout":"vertical","colon":false}},_vm._l((_vm.alertCount),function(item){return _c('a-descriptions-item',{key:item.name,attrs:{"label":_vm.$t(`alert_severity.${item.name}`)}},[_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$emit('filterAlerts', item.name)}}},[_vm._v(_vm._s(item.value))])])}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }