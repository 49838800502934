<template>
  <div class="status-container">
    <div style="width: 80px; height: 70px; text-align: center; float: left">
      <slot name="typeImg"></slot>
      <div
        style="
          margin-top: 4px;
          font-size: 14px;
          color: rgba(0, 0, 0.85);
          font-weight: 500;
        "
      >
        {{ getSourceType(sourceType) }}
      </div>
    </div>
    <div
      style="
        float: right;
        margin-left: 10px;
        height: 70px;
        line-height: 84px;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.45);
      "
    >
      总数
    </div>
    <div
      :style="{
        float: 'right',
        height: '70px',
        lineHeight: '70px',
        fontSize: '40px',
        color: '#555761',
        fontWeight: 400,
        cursor: 'pointer'
      }"
    >
      <span @click="$emit('changeList', type)">{{ countTotal }}</span>
    </div>
    <div style="clear: both"></div>
    <div
      style="
        display: flex;
        flex-wrap: wrap;
        margin-top: 16px;
        margin-left: -24px;
        padding: 0 8px;
      "
    >
      <div
        v-for="item in statusCount"
        :key="item.name"
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 50%;
          padding: 4px 0 0 24px;
        "
      >
        <div>
          <div
            :style="{
              width: '6px',
              height: '6px',
              borderRadius: '50%',
              background: getStatusColor(item.name),
              display: 'inline-block'
            }"
          ></div>
          <div
            style="
              display: inline-block;
              color: rgba(0, 0, 0, 0.65);
              font-size: 12px;
              width: 48px;
              margin-left: 6px;
            "
          >
            {{ $t(`source_status.${item.name}`) }}
          </div>
        </div>
        <div
          @click="$emit('filterByStatus', item.name)"
          :style="{
            fontSize: '16px',
            color: getStatusColor(item.name),
            cursor: 'pointer'
          }"
        >
          {{ item.value }}
        </div>
      </div>
    </div>
    <div class="custom-progress">
      <div
        class="progress-bg"
        :style="{ background: getStatusColor(item) }"
        v-for="item in sourceStatuses"
        :key="item"
        :ref="`${item}Div`"
      ></div>
    </div>
    <div style="font-weight: 400; color: #2e8df5; margin: 16px 0 8px 4px">
      未恢复告警分布
    </div>
    <a-descriptions :column="4" layout="vertical" :colon="false">
      <a-descriptions-item
        v-for="item in alertCount"
        :key="item.name"
        :label="$t(`alert_severity.${item.name}`)"
      >
        <span @click="$emit('filterAlerts', item.name)" style="cursor: pointer">{{ item.value }}</span>
      </a-descriptions-item>
    </a-descriptions>
  </div>
</template>

<script>
import { getAlertCount } from '@/api/alert'
import { getDatabaseAlertCount } from '@/api/database'
import { getMiddlewareAlertCount } from '@/api/middleware'
import { getNetworkDeviceAlertCount } from '@/api/network-device'
import { getOSAlertCount } from '@/api/os'
import {
  greenColor,
  redColor,
  grayColor,
  orangeColor,
  sourceStatuses
} from '@/utils/const'

export default {
  name: 'StatusCard',
  props: {
    sourceType: {
      type: String,
      default: 'network_device'
    },
    type: {
      type: [String, Array]
    },
    countFunc: {
      type: Function
    }
  },
  computed: {},
  data () {
    return {
      sourceStatuses,
      normalDiv: undefined,
      abnormalDiv: undefined,
      alertDiv: undefined,
      unknownDiv: undefined,
      alertCount: [
        { name: 'disaster', value: 0 },
        { name: 'high', value: 0 },
        { name: 'average', value: 0 },
        { name: 'warning', value: 0 }
      ],
      statusCount: [
        { name: 'normal', value: 0 },
        { name: 'abnormal', value: 0 },
        { name: 'alert', value: 0 },
        { name: 'unknown', value: 0 }
      ],
      countTotal: 0
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.countTotal = 0
      this.normalDiv = this.$refs.normalDiv[0]
      this.abnormalDiv = this.$refs.abnormalDiv[0]
      this.alertDiv = this.$refs.alertDiv[0]
      this.unknownDiv = this.$refs.unknownDiv[0]
      this.alertCount = [
        { name: 'disaster', value: 0 },
        { name: 'high', value: 0 },
        { name: 'average', value: 0 },
        { name: 'warning', value: 0 }
      ]
      this.statusCount = [
        { name: 'normal', value: 0 },
        { name: 'abnormal', value: 0 },
        { name: 'alert', value: 0 },
        { name: 'unknown', value: 0 }
      ]
      this.fetch()
    },
    fetch () {
      const params = { count_of: 'status' }
      if (this.sourceType === 'database' && this.type) params.database_type = this.type
      if (this.sourceType === 'middleware' && this.type) params.middleware_type = this.type
      if (this.sourceType === 'network_device' && this.type) params.device_type = this.type
      if (this.sourceType === 'os' && this.type) params.os_type = this.type
      // 1.获取设备状态统计
      this.countFunc(params).then(res => {
        res.data.data.forEach(item => {
          const index = this.statusCount.findIndex(alert => alert.name === item.name)
          if (index !== -1) this.statusCount[index].value = item.value
        })
        this.statusCount.forEach(item => {
          this.countTotal += item.value
        })
        // 计算百分比
        this.statusCount.forEach(item => {
          if (item.value > 0) {
            const percent = parseInt((item.value / this.countTotal) * 10000) / 100
            const divName = item.name + 'Div'
            this[divName].style.width = percent + '%'
          }
        })
      })

      // 2.获取设备告警统计
      const alertParams = {
        count_of: 'severity',
        recovered: false
      }
      let getAlertCountFunc
      if (this.sourceType === 'database') {
        getAlertCountFunc = getDatabaseAlertCount
        if (this.type) alertParams.database_type = this.type
      } else if (this.sourceType === 'middleware') {
        getAlertCountFunc = getMiddlewareAlertCount
        if (this.type) alertParams.middleware_type = this.type
      } else if (this.sourceType === 'network_device') {
        getAlertCountFunc = getNetworkDeviceAlertCount
        if (this.type) alertParams.device_type = this.type
      } else if (this.sourceType === 'os') {
        getAlertCountFunc = getOSAlertCount
        if (this.type) alertParams.os_type = this.type
      } else {
        if (this.sourceType) alertParams.source_type = this.sourceType
        getAlertCountFunc = getAlertCount
      }
      getAlertCountFunc(alertParams).then(res => {
        res.data.data.forEach(item => {
          const index = this.alertCount.findIndex(alert => alert.name === item.name)
          if (index !== -1) this.alertCount[index].value = item.value
        })
        this.$emit('severityData', this.alertCount.map(item => {
          return {
            name: this.$t(`alert_severity.${item.name}`),
            value: item.value
          }
        }))
      })
    },
    getStatusColor (status) {
      if (status === 'normal') return greenColor
      else if (status === 'abnormal') return redColor
      else if (status === 'alert') return orangeColor
      return grayColor
    },
    getSourceType (v) {
      if (v === 'os' && this.type) return this.$t(`os_type.${this.type}`)
      if (v === 'network_device' && this.type) {
        if (Array.isArray(this.type)) return '安全设备'
        return this.$t(`network_device_type.${this.type}`)
      }
      return this.$t(`source_type.${v}`)
    }
  }
}
</script>

<style lang="less">
.status-container {
  overflow: hidden;

  .custom-progress {
    height: 7px;
    background: #f5f9fd;
    width: 100%;
    margin: 8px 0;
    border-radius: 3px;
    overflow: hidden;

    .progress-bg {
      height: 7px;
      float: left;
    }
  }

  .ant-descriptions {
    background: #f0f9ff;
    border-radius: 8px;
    padding-bottom: 10px;
    margin: 0 4px;
    .ant-descriptions-row {
      & > td {
        padding-bottom: 1px;
      }
      .ant-descriptions-item {
        text-align: center;
        .ant-descriptions-item-label {
          font-size: 12px;
          color: #9fa4a8;
          line-height: 20px;
          margin-top: 12px;
          &::after {
            margin: 0;
            top: 0;
          }
        }
        .ant-descriptions-item-content {
          font-size: 18px;
          font-weight: 400;
          line-height: 25px;
          span {
            font-size: 18px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.85);
            line-height: 25px;
          }
          span.cBlue {
            color: #096dd9;
          }
          span.text {
            font-size: 16px;
            font-weight: 500;
            color: #096dd9;
            line-height: 22px;
          }
          span.abnormal {
            color: #ff5b73;
          }
        }
      }
    }
  }
}
</style>
